<template>
    <v-container
            id="user-profile"
            fluid
            tag="section"
    >
        <v-row justify="center">
            <v-col
                    cols="12"
                    md="12"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Form Barang Masuk
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Tambah / Ubah data Barang Masuk
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <!--<v-text-field-->
                                    <!--label="Tanggal"-->
                                    <!--class="purple-input"-->
                                    <!--v-model="date"-->
                                    <!--/>-->
                                    <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="dateFormatted"
                                                    class="purple-input"
                                                    label="Tanggal"
                                                    readonly
                                                    v-on="on"
                                            >
                                                <v-icon slot="prepend" color="green" size="30">mdi-calendar
                                                </v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <input type="hidden" v-model="supplier_id"/>
                                    <v-text-field
                                            class="purple-input"
                                            readonly
                                            label="Supplier"
                                            v-model="supplier_name"
                                    >
                                        <v-icon slot="append" color="red" size="30"
                                                @click="openModalSupplier()">mdi-magnify
                                        </v-icon>
                                        <!--<span slot="append" color="red" size="30"-->
                                        <!--@click="openModalBarang(index)">open-->
                                        <!--</span>-->
                                    </v-text-field>
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="12"
                                >
                                    <v-simple-table>
                                        <thead>
                                        <tr>
                                            <th class="primary--text">
                                                No
                                            </th>
                                            <th class="primary--text">
                                                Barang
                                            </th>
                                            <th class="primary--text" width="80">
                                                Qty Unit
                                            </th>
                                            <th class="primary--text">
                                                Ukuran/Unit
                                            </th>
                                            <th class="primary--text">
                                                Harga
                                            </th>
                                            <th class="primary--text">
                                                Subtotal
                                            </th>
                                            <th class="primary--text">
                                                Hapus
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr v-for="(item, index) in barang" :key="index">
                                            <td>{{(index + 1)}}<input type="hidden" name="id" id="id"
                                                                      v-model="item.id"/>
                                                <input type="hidden" name="date" id="date" v-model="item.date"/>
                                            </td>
                                            <td>
                                                <v-text-field
                                                        class="purple-input"
                                                        readonly
                                                        v-model="item.name"
                                                >
                                                    <v-icon slot="append" color="red" size="30"
                                                            @click="openModalBarang(index)">mdi-magnify
                                                    </v-icon>
                                                    <!--<span slot="append" color="red" size="30"-->
                                                    <!--@click="openModalBarang(index)">open-->
                                                    <!--</span>-->
                                                </v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field
                                                        class="purple-input text-right"
                                                        v-model="item.qty"
                                                        type="number"
                                                />
                                                <!--<vue-numeric class="purple-input"-->
                                                <!--currency-symbol-position="suffix"-->
                                                <!--separator="."-->
                                                <!--v-model="item.qty">-->

                                                <!--</vue-numeric>-->
                                            </td>
                                            <td>
                                                <v-text-field
                                                        class="purple-input"
                                                        v-model="item.weight"
                                                        :suffix="item.uom_code"
                                                        type="number"
                                                />
                                                <!--<vue-numeric class="purple-input"-->
                                                <!--:currency="item.uom_code"-->
                                                <!--currency-symbol-position="suffix"-->
                                                <!--separator="."-->
                                                <!--v-model="item.weight">-->

                                                <!--</vue-numeric>-->
                                            </td>
                                            <td>
                                                <v-text-field
                                                        class="purple-input"
                                                        v-model="item.wholesale_prices"
                                                        prefix="Rp"
                                                />
                                                <input type="hidden" v-model="item.wholesale_prices" v-money="money"/>
                                                <!--<money class="purple-input form-input input-lg" v-model="item.wholesale_prices" v-bind="money"/>-->
                                                <!--type="number"-->
                                            </td>
                                            <td class="text-right">
                                                <v-text-field
                                                        class="purple-input"
                                                        v-model="item.subtotal"
                                                        prefix="Rp"
                                                        readonly
                                                />
                                                <!--v-model="item.subtotal"-->
                                                <!--<input type="hidden" v-model="item.subtotal" v-money="money" />-->
                                            </td>
                                            <td class="text-center">
                                                <v-icon
                                                        color="error"
                                                        class="mx-1"
                                                        @click="hapus(index)"
                                                >
                                                    mdi-close
                                                </v-icon>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                                <v-col
                                        cols="12"
                                        class="text-center"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="tambahBarang"
                                    >
                                        <v-icon left>mdi-plus</v-icon>
                                        Tambah
                                    </v-btn>
                                </v-col>

                                <v-col
                                        cols="12"
                                        class="text-right"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="simpan"
                                    >
                                        Simpan
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-dialog v-model="modalSupplier" persistent min-width="350" max-width="70%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">

                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Supplier
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalSupplier = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchSupplier"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersSupplier"
                                            :items="itemsSupplier"
                                            :search="searchSupplier"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihSupplier(item)">
                                                mdi-check-bold
                                            </v-icon>
                                            <!--<span class="mx-1" color="green darken-2"-->
                                            <!--@click="pilihSupplier(item)">Pilih-->
                                            <!--</span>-->
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalSupplier = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="modalBarang" persistent min-width="350" max-width="70%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">

                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Barang
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalBarang = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchBarang"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersBarang"
                                            :items="itemsBarang"
                                            :search="searchBarang"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihBarang(item)">
                                                mdi-check-bold
                                            </v-icon>
                                            <!--<span class="mx-1" color="green darken-2"-->
                                            <!--@click="pilihBarang(item)">Pilih-->
                                            <!--</span>-->
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalBarang = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false"
                    >
                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'
    import VueNumeric from 'vue-numeric'
    import {Money} from 'v-money'

    export default {
        name: 'FormGoodsReceipt',
        components: {
            Money
        },
        data() {
            return {
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: '',
                menu: false,
                modal: false,
                snackbar: false,
                dialog: false,
                message: '',
                color: 'success',
                indexPilih: 0,
                supplier_id: 0,
                supplier_name: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: '',
                    precision: 0,
                    masked: true /* doesn't work with directive */
                },
                barang: [{
                    id: null,
                    fk_goods_id: 0,
                    name: '',
                    qty: 0,
                    weight: 0,
                    uom_code: '',
                    wholesale_prices: 0,
                    subtotal: 0,
                    date: new Date().toISOString().substr(0, 10),
                    fk_supplier_id: this.supplier_id
                }],
                deletedItems: [],
                modalBarang: false,
                searchBarang: '',
                headersBarang: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Deskripsi',
                        filterable: true,
                        value: 'desc',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Kategori',
                        filterable: true,
                        value: 'category_name',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Stok',
                        filterable: true,
                        value: 'stock',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: true,
                        text: 'Satuan',
                        filterable: true,
                        value: 'uom_name',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsBarang: [],
                modalSupplier: false,
                searchSupplier: '',
                headersSupplier: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Alamat',
                        filterable: true,
                        value: 'address',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Telp',
                        filterable: true,
                        value: 'telp',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: false,
                        text: 'Aksi',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsSupplier: [],
                isLoadData: false,
                prices: 0,
                strprice: ''
            }
        },
        computed: {
            ...mapState(['currentUser']),
            computedDateFormatted() {
                return this.formatDate(this.date)
            },

        },
        watch: {
            barang: {
                handler: function (val, oldVal) {
//                    let t = this
//                    console.log(t.prices)
                    val.forEach(function (item, i) {
//                        t.strprice = item.wholesale_prices.toString()
//                        console.log(t.strprice)
//                        console.log('index '+i)
//                        t.prices = t.strprice.replace(".","")
//                        console.log(prices, prices*2)
//                        this.produk.retail_price = this.produk.retail_price.replace(/\./g,'')
                        item.subtotal = parseFloat(item.qty.toString().replace(/\./g,'')) * parseFloat(item.wholesale_prices.toString().replace(/\./g,''))
//                        console.log('subtotal '+item.subtotal, 'qty '+item.qty, 'prices '+t.prices)
                    })
                },
                deep: true
            },
            date: function (val) {

                this.formatDate(val)

                this.barang.forEach(function (item, i) {
                    item.date = val
                })
            }
        },
        mounted: function () {
            this.dataBarang()
            this.dataSupplier()
            let date = this.$route.params.date
//            console.log('edit')
//            console.log(date)
            if (date !== undefined) {
                this.dataBarangMasuk(date)
                this.dateFormatted = date
                const [day, month, year] = date.split('-')
                this.date = `${year}-${month}-${day}`

            } else {
                this.computedDateFormatted
            }

        },
        methods: {
            formatDate: function (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                this.dateFormatted = `${day}-${month}-${year}`
            },
            openModalBarang: function (i) {
                this.modalBarang = true
                this.indexPilih = i
            },
            dataBarang: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json',
                }
                axios
                ({method: 'get', url: ApiUrl + 'goods', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        this.isLoadData = true
                        if (response.data.success) {
                            this.itemsBarang = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihBarang: function (item) {
                console.log('pilih kategori')
                console.log(item)
                this.barang[this.indexPilih].name = item.name
                this.barang[this.indexPilih].fk_goods_id = item.id
                this.barang[this.indexPilih].uom_code = item.uom_code
                this.barang[this.indexPilih].fk_supplier_id = this.supplier_id
                this.modalBarang = false
            },
            openModalSupplier: function (i) {
                this.modalSupplier = true
//                this.indexPilih = i
            },
            dataSupplier: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json',
                }
                axios
                ({method: 'get', url: ApiUrl + 'suppliers', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        this.isLoadData = true
                        if (response.data.success) {
                            this.itemsSupplier = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihSupplier: function (item) {
                console.log('pilih supplier')
                console.log(item)
                this.supplier_name = item.name
                this.supplier_id = item.id
                this.modalSupplier = false
            },
            dataBarangMasuk: function (date) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'goodsreceipt/' + date, headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.barang = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            tambahBarang: function () {
                this.barang.push({
                    id: null,
                    fk_goods_id: 0,
                    name: '',
                    qty: 0,
                    weight: 0,
                    uom_code: '',
                    wholesale_prices: 0,
                    subtotal: 0,
                    date: this.date,
                    fk_supplier_id: this.supplier_id,
                })
            },
            hapus: function (index) {
                this.deletedItems.push(this.barang[index].id)

                if (this.barang.length > 1)
                    this.barang.splice(index, 1)
                else {
                    this.barang[index].id = null
                    this.barang[index].fk_goods_id = 0
                    this.barang[index].name = ''
                    this.barang[index].qty = 0
                    this.barang[index].weight = 0
                    this.barang[index].uom_code = ''
                    this.barang[index].wholesale_prices = 0
                    this.barang[index].subtotal = 0
                    this.barang[index].date = this.date
                    this.barang[index].fk_supplier_id = this.supplier_id
                }


            },
            simpan: function () {
//                if (this.barang.name && this.barang.name !== '' && this.barang.fk_goods_category_id && this.barang.fk_goods_category_id !== 0 &&
//                    this.barang.fk_uom_id && this.barang.fk_uom_id !== 0) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json',
                }
                let data = {
                    data: [],
                    deletedItems: this.deletedItems
                };
                var globalThis = this;
                var error = false;
                if(this.date == '' || this.supplier_id == ''){
                    this.snackbar = true
                    this.color = 'warning'
                    this.message = 'Tanggal dan supplier harus diisi                    '
                    return true;
                }
                this.barang.some(function (item, i) {
                    if(!item.fk_goods_id || item.qty == '' || item.qty == 0 || item.weight == '' || item.weight == 0 || item.wholesale_prices == '' ||
                        item.wholesale_prices == 0){
                        globalThis.snackbar = true
                        globalThis.color = 'warning'
                        globalThis.message = 'Barang, qty, ukuran/unit, dan harga harus diisi                    '
                        error = true
                        return true;
                    }
                    data.data.push({
                        id: item.id,
                        fk_goods_id: item.fk_goods_id,
                        qty: item.qty,
                        weight: item.weight,
                        wholesale_prices: item.wholesale_prices,
                        subtotal: item.subtotal,
                        date: item.date,
                        fk_supplier_id: item.fk_supplier_id,
                    })
                });
                if(error)
                    return true;
                this.dialog = true
                axios
                ({method: 'post', url: ApiUrl + 'goodsreceipt/simpan', data: data, headers: header})
                    .then(response => {
                        console.log(response)
                        this.dialog = false
                        if (response.data.success) {
                            this.snackbar = true
                            this.color = 'success'
                            this.message = 'Berhasil simpan                    '
                            this.barang = [{
                                id: null,
                                fk_goods_id: 0,
                                name: '',
                                qty: 0,
                                weight: 0,
                                uom_code: '',
                                wholesale_prices: 0,
                                subtotal: 0,
                                date: '',
                            }]
                            this.$refs.form.resetValidation()
                        } else {
                            this.snackbar = true
                            this.color = 'error'
                            this.message = 'Gagal simpan                    '
                        }
                    })
                    .catch(error => {
                        this.dialog = false
                        console.log(error)
                    })
//                } else {
//                    this.snackbar = true
//                    this.color = 'error'
//                    this.message = 'Nama, kategori dan satuan stok harus diisi'
//                }
            },
        }
    }
</script>
